import React, { Component } from 'react'
import BugIcon from '@material-ui/icons/BugReport'
import { Typography, Divider, Paper } from '@material-ui/core'

/**
 * The error boundary surrounds the router and catches any unhandled errors.
 * If we don't have one React will unmount the *entire* App should an error be thrown during a render.
 * In practice this will mean just a white screen for the user, which doesn't tell them anything.
 */
export default class ErrorBoundary extends Component<{}, { error: Error | null }> {
  constructor (props: {}) {
    super(props)
    this.state = {
      error: null
    }
  }

  static getDerivedStateFromError (error: Error) {
    return { error }
  }

  render () {
    if (this.state.error) {
      return (
        <Paper>
          <Typography variant='h5' style={{ paddingBottom: 10, textAlign: 'left' }}>
            Error
          </Typography>
          <Divider />
          <BugIcon />
          <h3>A real proper error occured.</h3>
          <p>An unhandled error occured in this page. Unsaved data is likely lost - sorry.</p>
          <button onClick={() => this.setState({ error: null })}>Reset</button>
        </Paper>
      )
    }

    return this.props.children
  }
}