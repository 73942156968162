import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { RouteChildrenProps } from 'react-router'
import BugForm, {BugFields} from '../components/BugForm'
import PaperSheet from '../components/common/Paper'

interface State {
  submitting: boolean,
  submitted: boolean,
  error: string | null
}

export default class BugFormPage extends React.Component<RouteChildrenProps, State> {
  constructor (props: RouteChildrenProps) {
    super(props)
    this.state = {
      submitting: false,
      submitted: false,
      error: null
    }
  }

  onSubmit = async (values: BugFields) => {
    console.log(values)
    this.setState({ submitting: true, error: null })
    this.setState({ submitted: true, submitting: false })
  }

  render () {
    return (
      <PaperSheet title='Support Form'>
        <BugForm 
          submitted={this.state.submitted}
          submitting={this.state.submitting}
          onSubmit={this.onSubmit}
          isDisabled={false}
        />
      </PaperSheet>
    )
  }
}
