import React from 'react'
import { Route, BrowserRouter, Switch,  RouteProps } from 'react-router-dom'
import ErrorBoundary from './ErrorBoundary'
import BugFormPage from '../routes/BugFormPage'

class Router extends React.Component{
    
    render() {
        return(
            <BrowserRouter>
                <React.Fragment>
                    <ErrorBoundary>
                        <Switch>
                            <Route path='/' exact component={BugFormPage}/>
                        </Switch>
                    </ErrorBoundary>
                </React.Fragment>
            </BrowserRouter>
        )
    }
}

export default Router
