export interface Solution {
  id: number
  name: string
}
export interface ImpactLevel {
  id: number
  name: string
}
export interface Browser {
  id: number
  name: string
}
const impacting: Solution = {
  id: 1,
  name: 'Impacting'
}

const solutions: Solution[] = [
  impacting
]

const low: ImpactLevel = {
  id: 1,
  name: 'Low'
}

const medium: ImpactLevel = {
  id: 2,
  name: 'Medium'
}

const high: ImpactLevel = {
  id: 3,
  name: 'High'
}

const impactLevels: ImpactLevel[] = [
  low,
  medium,
  high
]

const chrome: Browser = {
  id: 1,
  name: 'Google Chrome'
}
const firefox: Browser = {
  id: 2,
  name: 'Firefox'
}
const edge: Browser = {
  id: 3,
  name: 'Microsoft edge'
}
const ie: Browser = {
  id: 4,
  name: 'Internet explorer'
}

const browsers: Browser[] = [
  chrome,
  firefox,
  edge,
  ie
]

export {
  solutions,
  impactLevels,
  browsers
}