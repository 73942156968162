import React from 'react'
import Paper from '@material-ui/core/Paper'
import createStyles from '@material-ui/core/styles/createStyles'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import { Theme } from '@material-ui/core'
import Helmet from 'react-helmet'

const styles = (theme: Theme) => createStyles({
  root: {
    backgroundColor: '#A30B1A',
    padding: '2em 5em'
  },
  paper: {
    ...theme.mixins.gutters(),
    // 8em is an educated guess. Is there a defined height for the AppBar component? Similar with footer - it's *about* 3em.
    minHeight: 'calc(100vh - 4em)',
    margin: '2em 5em',
    backgroundSize: '100vw',
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
    paddingTop: '1.5em',
    paddingBottom: '1.5em'
  }
})

/**
 * A PaperSheet is the box for content on a page. It also handles setting the page title.
 * You may add elements to the right hand side of the title.
 */
interface Props extends WithStyles{
  title: string
}

class PaperSheet extends React.Component<Props> {

  render () {
    const { classes, children } = this.props

    return (
      <div className={classes.root}>
        <Paper id='paperSheet' elevation={2} className={classes.paper}>
          <Helmet title={this.props.title} />
          <div>{children}</div>
        </Paper>
      </div>
    )
  }
}

export default(withStyles(styles)(PaperSheet))