import React, { Component } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Grid, { GridSize } from '@material-ui/core/Grid'
import { FieldConfig, Field, FieldProps } from 'formik'
import { Solution, solutions, impactLevels, ImpactLevel, Browser } from '../Data'

/**
 * A Formik-compatible field that allows a role type to be selected. It returns a number type - not a string!
 */
export default class SmartSelectorField extends Component<FieldConfig & Props> {
  render () {
    return (
      <Field {...this.props} component={SmartSelector} />
    )
  }
}
interface Props {
  disabled?: boolean | undefined
  /**
   * Caution, will disable validation for this field.
   */
  autoSubmitOnChange?: boolean
  data: Solution[] | ImpactLevel[] | Browser[]
  label: string
  xs?: GridSize
}

class SmartSelector extends Component<FieldProps & Props> {


  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist()
    if (this.props.autoSubmitOnChange) {
      this.props.form.setFieldValue(this.props.field.name, e.target.value, false)
      this.props.form.submitForm()
    } else {
      this.props.field.onChange(e)
    }
  }

  render () {

    var errorMessage = this.props.form.touched[this.props.field.name] && this.props.form.errors[this.props.field.name]
    return (
      <Grid item xs={this.props.xs || 4}>
        <TextField
          select
          required
          name={this.props.field.name}
          label={this.props.label}
          variant='outlined'
          error={Boolean(errorMessage)}
          helperText={errorMessage}
          value={this.props.field.value || ''}
          onChange={this.onChange}
          onBlur={this.props.field.onBlur}
          disabled={this.props.data.length === 0 || this.props.disabled}
          fullWidth
        >
          {this.props.data.map(d => (
            <MenuItem key={d.id} value={d.name}>
              {d.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    )
  }
}
