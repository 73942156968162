import React, { Component } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import Router from './components/Router'

/**
 * This is the entrypoint for the application. It doesn't do a lot.
 */
export default class App extends Component {
  render () {
    return (
      <React.Fragment>
        <Router />
        <CssBaseline />
      </React.Fragment>
    )
  }
}
